import React from 'react';

export const DarkIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor">
    <path transform="translate(4, 4)" fillRule="evenodd" d="M4.812 9.623a4.82 4.82 0 0 0 4.811-4.812A4.82 4.82 0 0 0 4.812 0 4.822 4.822 0 0 0 0 4.812a4.82 4.82 0 0 0 4.812 4.811z" clipRule="evenodd"></path>
  </svg>
);

export const LightIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20" fill="currentColor">
    <path d="M19 9.199h-.98c-.553 0-1 .359-1 .801 0 .441.447.799 1 .799H19c.552 0 1-.357 1-.799 0-.441-.449-.801-1-.801zM10 4.5A5.483 5.483 0 0 0 4.5 10c0 3.051 2.449 5.5 5.5 5.5 3.05 0 5.5-2.449 5.5-5.5S13.049 4.5 10 4.5zm0 9.5c-2.211 0-4-1.791-4-4 0-2.211 1.789-4 4-4a4 4 0 0 1 0 8zm-7-4c0-.441-.449-.801-1-.801H1c-.553 0-1 .359-1 .801 0 .441.447.799 1 .799h1c.551 0 1-.358 1-.799zm7-7c.441 0 .799-.447.799-1V1c0-.553-.358-1-.799-1-.442 0-.801.447-.801 1v1c0 .553.359 1 .801 1zm0 14c-.442 0-.801.447-.801 1v1c0 .553.359 1 .801 1 .441 0 .799-.447.799-1v-1c0-.553-.358-1-.799-1zm7.365-13.234c.391-.391.454-.961.142-1.273s-.883-.248-1.272.143l-.7.699c-.391.391-.454.961-.142 1.273s.883.248 1.273-.143l.699-.699zM3.334 15.533l-.7.701c-.391.391-.454.959-.142 1.271s.883.25 1.272-.141l.7-.699c.391-.391.454-.961.142-1.274s-.883-.247-1.272.142zm.431-12.898c-.39-.391-.961-.455-1.273-.143s-.248.883.141 1.274l.7.699c.391.391.96.455 1.272.143s.249-.883-.141-1.273l-.699-.7zm11.769 14.031l.7.699c.391.391.96.453 1.272.143.312-.312.249-.883-.142-1.273l-.699-.699c-.391-.391-.961-.455-1.274-.143s-.248.882.143 1.273z"></path>
  </svg>
);

export const AccountIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
    <path d="M12 2a5 5 0 100 10A5 5 0 0012 2zm0 8a3 3 0 110-6 3 3 0 010 6zm0 2c-4.42 0-8 1.79-8 4v2h16v-2c0-2.21-3.58-4-8-4zm-6 6v-.99C6 17.34 8.69 16 12 16s6 1.34 6 3.01V18H6z"></path>
  </svg>
);

export const Arrow: React.FC = () => (
  <svg width="25" height="25" style={{ fill: 'currentColor' }} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <path d="M 755.627 481.707 L 542.293 268.373 C 538.236 264.489 533.451 261.444 528.213 259.413 C 517.826 255.146 506.174 255.146 495.787 259.413 C 490.549 261.444 485.764 264.489 481.707 268.373 L 268.373 481.707 C 245.054 505.026 255.723 544.846 287.579 553.381 C 302.363 557.343 318.137 553.116 328.96 542.293 L 469.333 401.493 L 466.398 751.752 C 466.398 784.597 499.997 799.254 528.441 782.832 C 541.642 775.21 548.796 766.018 548.796 750.774 L 554.667 401.493 L 695.04 542.293 C 711.73 559.121 738.937 559.121 755.627 542.293 C 772.454 525.603 772.454 498.397 755.627 481.707 Z" style={{transformBox: 'fill-box' }} transform="matrix(0.707107, 0.707107, -0.707107, 0.707107, -7.614194, -3.153936)"/>
</svg>
);

export const Help: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1024 1024" width="30" height="30">
    <path style={{ fill: 'rgb(49, 103, 155)' }} d="M512 1024C229.23 1024 0 794.77 0 512S229.23 0 512 0s512 229.23 512 512-229.23 512-512 512z m-7.5-176c25.666 0 46.5-20.834 46.5-46.5S530.166 755 504.5 755 458 775.834 458 801.5s20.834 46.5 46.5 46.5z m200.251-453.21c2.579-48.654-14.936-95.375-49.535-131.57-37.071-38.987-89.507-61.327-143.77-61.22-51.47 0.108-99.823 20.192-136.141 56.602C338.986 295.012 319 343.343 319 394.789c0 21.373 17.3 38.665 38.683 38.665 21.382 0 38.682-17.292 38.682-38.665 0-63.583 51.792-115.35 115.296-115.458h0.214c32.988 0 64.794 13.533 87.251 37.161 19.771 20.73 29.764 47.043 28.367 74.216-1.182 23.306-2.364 45.324-71.67 114.6-43.303 43.283-82.63 86.566-87.895 140.16-2.042 21.266 13.431 40.277 34.707 42.317 1.29 0.108 2.578 0.215 3.868 0.215 19.664 0 36.533-14.929 38.468-34.906 2.47-25.455 26.862-54.453 65.545-93.119 76.29-76.256 91.549-115.458 94.235-165.186z"/>
  </svg>
);

export const Community: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1024 1024" width="30" height="30">
  <path style={{ fill: 'rgb(227, 166, 0)' }} d="M 512 1024 C 229.23 1024 0 794.77 0 512 C 0 229.23 229.23 0 512 0 C 794.77 0 1024 229.23 1024 512 C 1024 794.77 794.77 1024 512 1024 Z"/>
  <path style={{ fill: 'rgb(0, 0, 0)' }} d="M 404.793 424.207 C 455.721 424.213 487.556 369.085 462.096 324.977 C 450.278 304.502 428.432 291.89 404.793 291.893 C 353.864 291.898 322.041 347.032 347.508 391.134 C 359.325 411.598 381.161 424.205 404.793 424.207 Z M 404.793 477.134 C 496.464 477.134 553.757 377.897 507.921 298.508 C 486.649 261.664 447.337 238.967 404.793 238.967 C 313.122 238.967 255.828 338.203 301.664 417.592 C 322.936 454.436 362.248 477.134 404.793 477.134 Z M 656.191 450.67 C 696.933 450.67 722.398 406.566 702.027 371.282 C 692.572 354.906 675.099 344.818 656.191 344.818 C 615.449 344.818 589.985 388.923 610.356 424.207 C 619.81 440.583 637.282 450.67 656.191 450.67 Z M 656.191 503.596 C 737.676 503.603 788.611 415.397 747.873 344.825 C 728.964 312.068 694.014 291.889 656.191 291.893 C 574.707 291.899 523.785 380.113 564.533 450.678 C 583.44 483.422 618.38 503.593 656.191 503.596 Z M 493.271 556.522 L 332.059 556.522 C 305.708 556.524 283.372 575.91 279.663 601.998 L 267.304 688.838 L 564.325 688.838 L 545.033 598.4 C 539.82 573.978 518.244 556.522 493.271 556.522 Z M 596.794 587.351 C 586.369 538.507 543.217 503.596 493.271 503.596 L 332.059 503.596 C 279.356 503.599 234.684 542.371 227.266 594.55 L 206.321 741.763 L 629.728 741.763 L 596.781 587.351 L 596.794 587.351 Z M 841.433 741.763 L 814.639 614.159 C 804.347 565.147 761.115 530.055 711.036 530.059 L 634.543 530.059 C 637.336 534.902 639.399 540.247 640.565 545.977 L 648.093 582.985 L 711.048 582.985 C 736.084 582.99 757.691 600.534 762.836 625.036 L 776.241 688.838 L 669.635 688.838 L 680.391 741.763 L 841.433 741.763 Z" transform="matrix(0.999979, 0.00655, -0.00655, 0.999979, 0, 0)"/>
</svg>
);

export const Academy: React.FC = () => (
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1024 1024" width="34" height="34">
<path d="M512 66.99074c-246.708891 0-446.708971 200.00008-446.708971 446.708972s200.00008 446.708971 446.708971 446.708971c246.712985 0 446.708971-200.00008 446.708971-446.708971S758.712985 66.99074 512 66.99074z m-35.381835 656.002718l-195.299011-0.066514c-8.728802-0.435928-24.215518-24.76094-24.580839-38.331004l0.004094-328.414775 17.395182 0.004093s0.004093 211.178677 0.004093 314.812989c76.906577 12.502755 146.187477 23.817452 215.771275 35.133172-0.002047 0.001023-7.966439 16.741289-13.294794 16.862039z m24.471344-36.207643c-96.907097-37.372166-198.434421-29.449729-198.434421-29.449729l0.159636-27.234271s-0.159636-211.081463-0.159636-325.69585c44.925189 4.77577 84.455507 6.687306 123.065873 13.656021 50.093908 9.036817 77.844949 33.367969 75.43097 92.965321l-0.062422 275.758508z m23.25361-275.758508c-2.410909-59.597353 25.328875-83.929528 75.41869-92.965321 38.626739-6.967692 78.157058-8.880251 123.069967-13.656021 0 114.614387-0.151449 325.69585-0.151449 325.69585l0.151449 27.234271s-101.519138-7.922437-198.426235 29.449729l-0.062422-275.758508z m219.135906 311.899637l-196.303898 0.066514c-5.320169-0.12075-13.300934-16.861016-13.300934-16.861016 69.583798-11.314697 139.876748-22.630417 216.784349-35.133172V356.186282h16.561187l0.042979 328.410681c-0.366344 13.570063-15.042601 37.894052-23.783683 38.329981z" fill="#8e119e" />
</svg>
);

export const CheckMark: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1024 1024" width="30" height="30" style={{ verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden' }} >
  <path style={{ fill: 'rgb(0, 110, 91)' }} d="M 512 1024 C 229.23 1024 0 794.77 0 512 C 0 229.23 229.23 0 512 0 C 794.77 0 1024 229.23 1024 512 C 1024 794.77 794.77 1024 512 1024 Z" />
  <path style={{ fill: 'rgb(255, 255, 255)' }} transform='matrix(0.8, 0, 0, 0.8, 90, 102)' d="M 442.009 795.227 C 430.184 795.189 418.904 790.245 410.863 781.574 L 203.503 560.987 C 181.004 537.01 192.898 497.669 224.912 490.173 C 239.769 486.694 255.354 491.406 265.796 502.534 L 441.583 689.84 L 800.409 297.307 C 820.975 271.698 861.551 277.955 873.447 308.57 C 879.627 324.474 875.737 342.531 863.556 354.48 L 473.583 781.147 C 465.616 789.976 454.327 795.079 442.436 795.227 L 442.009 795.227 Z"/>
</svg>
)