export const BACKEND_URL = 'http://localhost:8000/api/v1'
export const scopes = 'eco.admin.app:delete ' +
  'eco.admin.app:read ' +
  'eco.admin.app:write ' +
  'eco.admin.appinstance:delete ' +
  'eco.admin.appinstance:read ' +
  'eco.admin.appinstance:write ' +
  'eco.admin.appinstanceconfiguration:delete ' +
  'eco.admin.appinstanceconfiguration:read ' +
  'eco.admin.appinstanceconfiguration:write ' +
  'eco.admin.apporganization:delete ' +
  'eco.admin.apporganization:read ' +
  'eco.admin.apporganization:write ' +
  'eco.admin.audit_log:read ' +
  'eco.admin.auditlog:read ' +
  'eco.admin.configuration:read ' +
  'eco.admin.configuration:write ' +
  'eco.admin.connection:delete ' +
  'eco.admin.connection:read ' +
  'eco.admin.connection:write ' +
  'eco.admin.member:delete ' +
  'eco.admin.member:read ' +
  'eco.admin.member:write ' +
  'eco.admin.organization:delete ' +
  'eco.admin.organization:read ' +
  'eco.admin.organization:write ' +
  'eco.user.app:read ' +
  'eco.user.appinstance:delete ' +
  'eco.user.appinstance:read ' +
  'eco.user.appinstance:write ' +
  'eco.user.appoverview:read ' +
  'eco.user.audit_log:read ' +
  'eco.user.auditlog:read ' +
  'eco.user.connection:delete ' +
  'eco.user.connection:read ' +
  'eco.user.connection:write ' +
  'eco.user.member:delete ' +
  'eco.user.member:read ' +
  'eco.user.member:write ' +
  'eco.user.organization:read ' +
  'eco.user.organization:write ' +
  'eco.user.offers:read ' +
  'eco.admin.offers:read ' +
  'eco.user.contract:read ' +
  'eco.admin.contract:read ' +
  'eco.admin.externalApp:read ' +
  'eco.user.ticket:read ' +
  'eco.admin.ticketConfig:read ' +
  'eco.admin.ticketConfig:write ' +
  'eco.admin.ticketConfig:delete '
