import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getAccessToken, login } from '../../auth0';
import authTokenUtil from '../../utils/authToken';
import { storeUserDataInLocalStorage } from '../../utils/userDetails';
import styles from './css/Login.module.css';

const Login: React.FC = () => {
  const exisitngToken = authTokenUtil.getToken()
  const [email, setEmail] = useState('');
  const [darkMode, setDarkMode] = useState<boolean>();

  const [isReady, setIsReady] = useState(false)
  const [authToken, setAuthToken] = useState<string | null>(null)

  useEffect(() => {
    const savedDarkMode = window.localStorage.getItem('darkMode') === 'true'
    setDarkMode(savedDarkMode);
  }, []);

  useEffect(() => {
    if (darkMode === undefined) {
      return;
    }
    document.documentElement.setAttribute('data-theme', darkMode ? 'dark' : 'light');
    window.localStorage.setItem('darkMode', (darkMode).toString());
  }, [darkMode]);

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessToken()
        if (token) {
          authTokenUtil.setToken(token)
          storeUserDataInLocalStorage(token)
          setAuthToken(token)
        }
      } catch (error) {
        console.log({ error })
      } finally {
        setIsReady(true)
      }
    }

    getToken().catch((error: Error) => {
      console.error(error)
    })
  }, [])

  const isValidEmail = (value: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(value)
  }
  
  const onClickLogin = (event: any) => {
    event.preventDefault();
    if (email) login(email);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  if (exisitngToken || authToken) return <Navigate to="/" />

  return (
    <div className={styles.xxBody}>
      <div className={styles.xxContainerLayout}>
        <header className={styles.xxAppHeader}>
          <div className={styles.xxAppHeaderContainer}>
            <h1 className={`${styles.xxAppLogo} ${styles.xxAppLogoBlue}`}>
              <span className={styles.xxAppLogoVignet} aria-hidden="true">XX</span>
              <span className={styles.xxAppLogoAppname}>Mijn xxllnc</span>
            </h1>
          </div>
        </header>

        <main className={styles.xxLoginContent}>
          <section className="xx-login-section" aria-labelledby="login-title">
            <header className="xx-login-header">
              <h2 id="login-title" className={styles.xxHeaderPage}>Inloggen</h2>
            </header>

            <form className={styles.xxLoginForm} onSubmit={(e) => e.preventDefault()}>
              <input 
                type="email" 
                className={styles.xxLoginInput} 
                placeholder="Gebruikersnaam (Email)" 
                autoFocus
                autoComplete="email"
                name="email"
                value={email}
                disabled={!(!authToken && isReady)}
                onChange={handleEmailChange}
              />
              <button
                type="button"
                className={`${styles.xxBtn} ${styles.xxBtnPrimary}`}
                onClick={onClickLogin}
                disabled={!isReady || !isValidEmail(email)}
              >
                Inloggen op mijn xxllnc
              </button>
            </form>
          </section>
        </main>

        <footer className={styles.xxLoginFooter}>
          <img src="./img/xxllnc-logo.png" alt="Bedrijfslogo xxllnc" className={styles.xxLoginFooterLogo} />
          <div className={styles.xxLoginFooterAppname}>
            <a href="https://xxllnc.nl" className={`${styles.xxLink} ${styles.xxLinkIncognito} ${styles.xxLinkSmall}`} target="_blank" rel="noopener noreferrer">
              Mijn xxllnc is een applicatie van xxllnc
            </a>
          </div>
        </footer>

        <aside className={styles.xxSideColumn}>
          <img
            src="./img/login-visual.jpg"
            className={styles.xxLoginImage}
            aria-hidden="true" 
            alt='mijn xxllnc login'
          />
        </aside>
      </div>
    </div>
  );
}

export default Login;
