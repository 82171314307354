import { FC } from 'react';
import { Academy, Arrow, CheckMark, Community, Help as HelpIcon } from '../icons/Icons';
import styles from './help.module.css';

const Help: FC = () => {
  return (
    <div className={styles.helpSection}>
      <h2>Heb je hulp nodig?</h2>
      <div className={styles.helpList}>
        <a href='https://xxllnc.topdesk.net/tas/public/ssp' className={styles.helpItem} target="_blank" rel="noopener noreferrer">
          <HelpIcon />
          <div className={styles.helpItemContent}>
            <span className={styles.helpTitle}>Support</span>
            <span className={styles.helpDescription}>Het team staat voor je klaar.</span>
          </div>
          <Arrow />
        </a>
        <a href='https://community.xxllnc.nl' className={styles.helpItem} target="_blank" rel="noopener noreferrer">
          <Community />
          <div className={styles.helpItemContent}>
            <span className={styles.helpTitle}>Community</span>
            <span className={styles.helpDescription}>Stel je vragen en deel je ervaringen.</span>
          </div>
          <Arrow />
        </a>
        <a href='https://xxllnc.studytube.nl' className={styles.helpItem} target="_blank" rel="noopener noreferrer">
          <Academy />
          <div className={styles.helpItemContent}>
            <span className={styles.helpTitle}>Academy</span>
            <span className={styles.helpDescription}>Leer nieuwe vaardigheden en verdiep je kennis.</span>
          </div>
          <Arrow />
        </a>
        <a href='https://status.xxllnc.nl' className={styles.helpItem} target="_blank" rel="noopener noreferrer">
          <CheckMark />
          <div className={styles.helpItemContent}>
            <span className={styles.helpTitle}>Beschikbaarheid</span>
            <span className={styles.helpDescription}>Bekijk de up-time van je applicaties.</span>
          </div>
          <Arrow />
        </a>
      </div>
    </div>
  );
}

export default Help;
